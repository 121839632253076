import React, { useEffect, useState } from "react";
import { Layout, Menu, Space, Typography, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../logo.png";
import {
  azkroflyz_contact_path,
  azkroflyz_home_path,
  azkroflyz_student_tours_path,
  careermap_tool_path,
  contact_path,
  home_path,
  mba_tool_path,
  student_tours_path,
} from "../../config/config";

const { Header } = Layout;
const { Title } = Typography;

interface NavBarProps {
  background: string;
}

const NavBar: React.FC<NavBarProps> = ({ background }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 50; // The scroll threshold
      setScrolled(window.scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navItems = [
    { key: "home", label: "HOME", path: azkroflyz_home_path },
    { key: "mba", label: "MBA", path: mba_tool_path },
    {
      key: "studyTour",
      label: "STUDENT TOUR",
      path: azkroflyz_student_tours_path,
    },
    {
      key: "careerMapping",
      label: "CAREER MAPPING",
      path: home_path,
    },
    {
      key: "contact",
      label: "CONTACT",
      path: azkroflyz_contact_path,
    },
  ];

  const handleMenuClick = (path: string) => {
    navigate(path);
    setIsDrawerVisible(false); // Close drawer after selection
  };

  return (
    <Header className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="navContent container">
        <Space
          onClick={() => navigate(home_path)}
          className="brand"
          size="small"
        >
          <img className="brandText" src={logo} alt="" width={150} />
          {/* <Title
            level={3}
            className="brandText"
            onClick={() => navigate(home_path)}
          >
            Azkroflyz
          </Title> */}
        </Space>

        {/* Desktop Menu */}
        <div className="desktopMenu">
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[
              navItems.filter((item) => item.path === path)[0]?.key,
            ]}
            items={navItems.map((item) => ({
              ...item,
              onClick: () => handleMenuClick(item.path),
            }))}
            className="navMenu"
          />
        </div>

        {/* Mobile Menu (Hamburger Icon) */}
        <div className="mobileMenu">
          <Button
            icon={<MenuOutlined />}
            onClick={() => setIsDrawerVisible(true)}
            className="hamburgerIcon"
          />
        </div>
      </div>

      {/* Drawer for Mobile Menu */}
      <Drawer
        title="Menu"
        placement="left"
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
        className="drawerMenu"
      >
        <Menu
          mode="vertical"
          defaultSelectedKeys={["home"]}
          items={navItems.map((item) => ({
            ...item,
            onClick: () => handleMenuClick(item.path),
          }))}
        />
      </Drawer>
    </Header>
  );
};

export default NavBar;
