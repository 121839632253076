import styled from "styled-components";

export const WorldMapComponentStyled = styled.div`
  .svg-map__location {
    fill:   rgb(221, 221, 221);
  }

  /* Customize the color of the highlighted city (e.g., Mumbai) */
 /* #ka, #mp ,#ap {
    fill: rgb(23, 78, 166) !important;
  } */
`;
