import React from "react";
import "./App.css";
import AppRoute from "./app-router";
import { AppContextProvider } from "./context/app-context";
import { ConfigProvider } from "antd";
import { theme } from "./config/theme";

function App() {
  return (
    <div className="App">
      <ConfigProvider theme={theme}>
        <AppContextProvider>
          <AppRoute />
        </AppContextProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
