export const colleges = [
  { institute_value: "amrita vishwa vidyapeetham", ug_degree: "MTECH" },
  { institute_value: "indian institute of management", ug_degree: "MBA" },
  { institute_value: "shri ram college of commerce", ug_degree: "BCOM" },
  { institute_value: "anna university", ug_degree: null },
  {
    institute_value: "iit guwahati  indian institute of technology",
    ug_degree: null,
  },
  { institute_value: "karpagam academy of higher education", ug_degree: null },
  { institute_value: "annamalai university", ug_degree: null },
  {
    institute_value: "manonmaniam sundaranar university tirunelveli",
    ug_degree: "MSC",
  },
  { institute_value: "institute of technology and science", ug_degree: null },
  { institute_value: "kongu engineering college", ug_degree: null },
  { institute_value: "anna university", ug_degree: "BE" },
  { institute_value: "psg college of arts and science", ug_degree: null },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "MCOM",
  },
  { institute_value: "alliance university", ug_degree: "MBA" },
  { institute_value: "heritage institute of technology", ug_degree: "BSC" },
  {
    institute_value: "university of agricultural sciences bangalore",
    ug_degree: "BSC",
  },
  { institute_value: "indian institute of management", ug_degree: null },
  { institute_value: "icfai university gangtok", ug_degree: "MBA" },
  { institute_value: "christ university", ug_degree: "MBA" },
  {
    institute_value: "indian institute of technology madras",
    ug_degree: "BSC",
  },
  {
    institute_value: "i.k. gujral punjab technical university",
    ug_degree: "MBA",
  },
  {
    institute_value: "indian institute of technology kharagpur",
    ug_degree: null,
  },
  { institute_value: "indian institute of science", ug_degree: "MSC" },
  { institute_value: "tamil nadu agricultural university", ug_degree: null },
  { institute_value: "christ university bangalore", ug_degree: null },
  { institute_value: "bharathiar university", ug_degree: "MCOM" },
  { institute_value: "bharathiar university", ug_degree: "MBA" },
  { institute_value: "kumaraguru college of technology", ug_degree: "MSC" },
  { institute_value: "amity university", ug_degree: null },
  { institute_value: "jain university", ug_degree: null },
  { institute_value: "kumaraguru college of technology", ug_degree: "MBA" },
  { institute_value: "amity university noida", ug_degree: "BSC" },
  { institute_value: "university of delhi", ug_degree: null },
  { institute_value: "sns college of technology", ug_degree: null },
  { institute_value: "indian institute of technology delhi", ug_degree: null },
  { institute_value: "chandigarh group of colleges", ug_degree: "BSC" },
  { institute_value: "christ university", ug_degree: null },
  { institute_value: "kumaraguru college of technology", ug_degree: null },
  { institute_value: "indian institute of science", ug_degree: null },
  {
    institute_value: "indian institute of technology delhi",
    ug_degree: "MTECH",
  },
  { institute_value: "gujarat university", ug_degree: null },
  {
    institute_value: "hindusthan college of arts and science",
    ug_degree: "MCA",
  },
  { institute_value: "jawaharlal nehru university", ug_degree: null },
  { institute_value: "bharathidasan university", ug_degree: "MBA" },
  { institute_value: "indian institute of technology kanpur", ug_degree: null },
  { institute_value: "anna university", ug_degree: "MCA" },
  { institute_value: "indian institute of technology bombay", ug_degree: null },
  { institute_value: "sri krishna college of technology", ug_degree: "BE" },
  { institute_value: "gujarat university", ug_degree: "BE" },
  { institute_value: "university of delhi", ug_degree: "BCOM" },
  {
    institute_value: "hindusthan college of arts and science",
    ug_degree: null,
  },
  { institute_value: "sri venkateswara university", ug_degree: "BCOM" },
  { institute_value: "coimbatore institute of technology", ug_degree: null },
  { institute_value: "kumaraguru college of technology", ug_degree: "BE" },
  { institute_value: "university of delhi", ug_degree: "Bachelors Degree" },
  { institute_value: "university of madras", ug_degree: "BBA" },
  { institute_value: "national institute of management", ug_degree: "MBA" },
  { institute_value: "alagappa university", ug_degree: "MBA" },
  { institute_value: "mysore university", ug_degree: null },
  { institute_value: "jai hind college", ug_degree: "BCOM" },
  { institute_value: "mumbai university", ug_degree: "BE" },
  {
    institute_value: "tata institute of social sciences",
    ug_degree: "Masters Degree",
  },
  {
    institute_value: "national law school of india university",
    ug_degree: null,
  },
  { institute_value: "indian institute of technology bombay", ug_degree: "BE" },
  {
    institute_value: "hr college of commerce and economics mumbai",
    ug_degree: "BCOM",
  },
  { institute_value: "amity university noida", ug_degree: "MBA" },
  { institute_value: "savitribai phule pune university", ug_degree: null },
  { institute_value: "institute of management studies", ug_degree: "PGDBA" },
  { institute_value: "university of delhi", ug_degree: "BA" },
  {
    institute_value: "institute of management technology ghaziabad",
    ug_degree: "MBA",
  },
  { institute_value: "savitribai phule pune university", ug_degree: "BSC" },
  {
    institute_value: "iit patna  indian institute of technology",
    ug_degree: null,
  },
  { institute_value: "university of madras", ug_degree: "MTECH" },
  { institute_value: "anna university", ug_degree: "MBA" },
  { institute_value: "university of delhi", ug_degree: "BSC" },
  {
    institute_value: "institute of management technology ghaziabad",
    ug_degree: null,
  },
  {
    institute_value: "iit hyderabad  indian institute of technology",
    ug_degree: "Masters Degree",
  },
  { institute_value: "mumbai university", ug_degree: "BCOM" },
  { institute_value: "savitribai phule pune university", ug_degree: "MBA" },
  { institute_value: "mahatma gandhi kashi vidyapith", ug_degree: "BA" },
  {
    institute_value: "mahatma gandhi university kottayam",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "indian institute of technology madras",
    ug_degree: "MSC",
  },
  {
    institute_value: "indian institute of technology delhi",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "icfai business school", ug_degree: "MBA" },
  { institute_value: "banasthali vidyapith", ug_degree: "MCA" },
  { institute_value: "delhi college of arts and commerce", ug_degree: "BA" },
  { institute_value: "upes", ug_degree: "Bachelors Degree" },
  {
    institute_value: "symbiosis institute of business management",
    ug_degree: "MBA",
  },
  {
    institute_value: "prince shri venkateshwara padmavathy engineering college",
    ug_degree: null,
  },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "MBA",
  },
  { institute_value: "university of delhi", ug_degree: "MBA" },
  { institute_value: "savitribai phule pune university", ug_degree: "BCOM" },
  { institute_value: "ahmedabad university ahmedabad", ug_degree: "PGDBA" },
  { institute_value: "manipal academy of higher education", ug_degree: "BSC" },
  { institute_value: "anna university", ug_degree: "BSC" },
  { institute_value: "birla institute of technology", ug_degree: "MBA" },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: "BSC",
  },
  { institute_value: "mysore university", ug_degree: "MSC" },
  { institute_value: "coimbatore institute of technology", ug_degree: "BE" },
  { institute_value: "bharati vidyapeeth", ug_degree: "MBA" },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: null,
  },
  {
    institute_value: "indian institute of technology roorkee",
    ug_degree: null,
  },
  { institute_value: "mahatma gandhi university kottayam", ug_degree: "MBA" },
  {
    institute_value: "christ university bangalore",
    ug_degree: "Masters Degree",
  },
  { institute_value: "kurukshetra university", ug_degree: "BSC" },
  { institute_value: "symbiosis international", ug_degree: "PGDBA" },
  { institute_value: "calcutta university", ug_degree: "BCOM" },
  { institute_value: "university of madras", ug_degree: "BE" },
  { institute_value: "shobhit university meerut", ug_degree: "MBA" },
  { institute_value: "osmania university", ug_degree: "MBA" },
  { institute_value: "sardar patel university", ug_degree: null },
  { institute_value: "vellore institute of technology", ug_degree: "BE" },
  { institute_value: "national institute of technology", ug_degree: "BE" },
  { institute_value: "indian institute of technology madras", ug_degree: null },
  { institute_value: "gujarat university", ug_degree: "MBA" },
  { institute_value: "christ university bangalore", ug_degree: "MBA" },
  { institute_value: "amity university", ug_degree: "BBA" },
  {
    institute_value: "sona college of technology salem",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "national institute of technology", ug_degree: null },
  { institute_value: "maharshi dayanand university", ug_degree: null },
  { institute_value: "savitribai phule pune university", ug_degree: "BE" },
  { institute_value: "annamalai university", ug_degree: "MBA" },
  { institute_value: "university of madras", ug_degree: "MBA" },
  {
    institute_value: "maharaja sayajirao university of baroda",
    ug_degree: "Masters Degree",
  },
  { institute_value: "jamia millia islamia", ug_degree: null },
  { institute_value: "ramanujan college", ug_degree: "BCOM" },
  {
    institute_value: "international institute of information technology",
    ug_degree: null,
  },
  {
    institute_value:
      "national institute of pharmaceutical education and research",
    ug_degree: "MSC",
  },
  { institute_value: "sharda university", ug_degree: null },
  { institute_value: "gla university mathura", ug_degree: "BE" },
  { institute_value: "gujarat university", ug_degree: "MCA" },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "Masters Degree",
  },
  { institute_value: "jamia millia islamia", ug_degree: "Masters Degree" },
  {
    institute_value: "ramakrishna mission vivekananda college chennai",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "symbiosis international", ug_degree: "BBA" },
  { institute_value: "indian institute of technology delhi", ug_degree: "BSC" },
  { institute_value: "gujarat university", ug_degree: "Bachelors Degree" },
  { institute_value: "alagappa university", ug_degree: null },
  { institute_value: "jaipur national university", ug_degree: "MBA" },
  {
    institute_value: "national institute of management",
    ug_degree: "Masters Degree",
  },
  { institute_value: "chandigarh university", ug_degree: "BSC" },
  { institute_value: "indian institute of foreign trade", ug_degree: null },
  { institute_value: "christ university", ug_degree: "BBA" },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "MCA",
  },
  { institute_value: "madras christian college", ug_degree: "BSC" },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: "BE",
  },
  { institute_value: "madurai kamaraj university", ug_degree: null },
  {
    institute_value: "iit bhubaneswar  indian institute of technology",
    ug_degree: null,
  },
  { institute_value: "osmania university", ug_degree: "BE" },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: null,
  },
  { institute_value: "amity university", ug_degree: "BSC" },
  { institute_value: "institute of management studies", ug_degree: "MBA" },
  { institute_value: "goa institute of management", ug_degree: null },
  { institute_value: "savitribai phule pune university", ug_degree: "MSC" },
  { institute_value: "gujarat university", ug_degree: "BCOM" },
  {
    institute_value: "institute of technology nirma university ahmedabad",
    ug_degree: "BE",
  },
  { institute_value: "university of delhi", ug_degree: "Master of Laws" },
  { institute_value: "miranda house", ug_degree: null },
  { institute_value: "utkal university", ug_degree: "BSC" },
  { institute_value: "lovely professional university", ug_degree: "BE" },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: "BBA",
  },
  { institute_value: "shyam lal college", ug_degree: "BA" },
  {
    institute_value: "national institute of technology",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "management development institute", ug_degree: null },
  { institute_value: "manipal university", ug_degree: null },
  { institute_value: "rkdf university bhopal", ug_degree: null },
  { institute_value: "institute of technology and science", ug_degree: "BE" },
  { institute_value: "great lakes institute of management", ug_degree: null },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "Master of Laws",
  },
  { institute_value: "bharathiar university", ug_degree: "BE" },
  { institute_value: "presidency university kolkata", ug_degree: "BSC" },
  { institute_value: "university of delhi", ug_degree: "Masters Degree" },
  { institute_value: "banaras hindu university", ug_degree: null },
  { institute_value: "gujarat university", ug_degree: "Bachelor of Laws" },
  { institute_value: "tata institute of social sciences", ug_degree: null },
  { institute_value: "national institute of technology", ug_degree: "MBA" },
  { institute_value: "sastra university thanjavur", ug_degree: null },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "visvesvaraya technological university", ug_degree: "BE" },
  { institute_value: "osmania university", ug_degree: "MSC" },
  {
    institute_value: "madras school of social work",
    ug_degree: "Masters Degree",
  },
  {
    institute_value: "mepco schlenk engineering college sivakasi",
    ug_degree: "BE",
  },
  { institute_value: "rkdf university bhopal", ug_degree: "BSC" },
  { institute_value: "international management institute", ug_degree: "MBA" },
  {
    institute_value: "institute of management technology ghaziabad",
    ug_degree: "MCA",
  },
  { institute_value: "jawaharlal nehru university", ug_degree: "MBA" },
  {
    institute_value: "junagadh agricultural university junagadh",
    ug_degree: null,
  },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "BA",
  },
  { institute_value: "birla institute of technology", ug_degree: "BE" },
  { institute_value: "management development institute", ug_degree: "MBA" },
  { institute_value: "maharshi dayanand university", ug_degree: "MTECH" },
  {
    institute_value: "institute of management studies",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "kurukshetra university", ug_degree: null },
  { institute_value: "osmania university", ug_degree: "BSC" },
  { institute_value: "ramjas college", ug_degree: null },
  { institute_value: "kurukshetra university", ug_degree: "MCA" },
  {
    institute_value: "institute of technology nirma university ahmedabad",
    ug_degree: null,
  },
  { institute_value: "university of madras", ug_degree: "BCA" },
  {
    institute_value: "institute of management technology ghaziabad",
    ug_degree: "Masters Degree",
  },
  {
    institute_value: "babasaheb bhimrao ambedkar university",
    ug_degree: "BSC",
  },
  { institute_value: "madurai kamaraj university", ug_degree: "MBA" },
  {
    institute_value: "guru jambheshwar university of science and technology",
    ug_degree: "MBA",
  },
  {
    institute_value: "indian institute of management",
    ug_degree: "Masters Degree",
  },
  { institute_value: "delhi technological university", ug_degree: null },
  { institute_value: "sri venkateswara college", ug_degree: "BE" },
  { institute_value: "university of delhi", ug_degree: "BBA" },
  { institute_value: "keshav mahavidyalaya new delhi", ug_degree: "BCOM" },
  { institute_value: "kurukshetra university", ug_degree: "MBA" },
  {
    institute_value: "indian institute of technology madras",
    ug_degree: "Masters Degree",
  },
  { institute_value: "icfai university gangtok", ug_degree: null },
  { institute_value: "central university of rajasthan", ug_degree: null },
  {
    institute_value: "iit guwahati  indian institute of technology",
    ug_degree: "MSC",
  },
  {
    institute_value: "sambhram academy of management studies",
    ug_degree: "MBA",
  },
  { institute_value: "gujarat university", ug_degree: "MSC" },
  { institute_value: "bharati vidyapeeth", ug_degree: null },
  { institute_value: "jadavpur university", ug_degree: null },
  { institute_value: "university of delhi", ug_degree: "Bachelor of Laws" },
  { institute_value: "manipal institute of technology", ug_degree: "MTECH" },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: null,
  },
  { institute_value: "alagappa university", ug_degree: "Masters Degree" },
  { institute_value: "stella maris college for women", ug_degree: null },
  { institute_value: "annamalai university", ug_degree: "BCOM" },
  { institute_value: "osmania university", ug_degree: "BCOM" },
  { institute_value: "institute of technology and science", ug_degree: "MBA" },
  {
    institute_value: "ramakrishna mission vivekananda college chennai",
    ug_degree: null,
  },
  {
    institute_value: "indian institute of technology madras",
    ug_degree: "MTECH",
  },
  { institute_value: "symbiosis international", ug_degree: "MBA" },
  { institute_value: "symbiosis international", ug_degree: null },
  { institute_value: "andhra university visakhapatnam", ug_degree: null },
  { institute_value: "delhi university", ug_degree: "BCOM" },
  { institute_value: "r v college of engineering", ug_degree: "BE" },
  {
    institute_value: "chaudhary charan singh haryana agricultural university",
    ug_degree: "Master of Laws",
  },
  { institute_value: "mumbai university", ug_degree: null },
  { institute_value: "hindu college", ug_degree: "Bachelors Degree" },
  { institute_value: "amity university noida", ug_degree: null },
  { institute_value: "sardar patel university", ug_degree: "BE" },
  {
    institute_value: "indian institute of technology kanpur",
    ug_degree: "BSC",
  },
  { institute_value: "bits pilani (pilani campus) pilani", ug_degree: "MBA" },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "amity university noida", ug_degree: "MA" },
  { institute_value: "bharathidasan university", ug_degree: null },
  { institute_value: "icfai business school", ug_degree: null },
  { institute_value: "deshbandhu college", ug_degree: "Bachelors Degree" },
  { institute_value: "panjab university", ug_degree: null },
  { institute_value: "osmania university", ug_degree: null },
  {
    institute_value: "institute of management and technology",
    ug_degree: "BSC",
  },
  { institute_value: "mgm medical college navi mumbai", ug_degree: null },
  { institute_value: "tilak maharashtra vidyapeeth", ug_degree: "MBA" },
  { institute_value: "manipal academy of higher education", ug_degree: "BE" },
  {
    institute_value: "manonmaniam sundaranar university tirunelveli",
    ug_degree: "BE",
  },
  { institute_value: "lovely professional university", ug_degree: null },
  { institute_value: "christ university bangalore", ug_degree: "BCOM" },
  { institute_value: "pes university", ug_degree: "BE" },
  { institute_value: "maharshi dayanand university", ug_degree: "BE" },
  {
    institute_value: "international institute of information technology",
    ug_degree: "BSC",
  },
  { institute_value: "indian institute of foreign trade", ug_degree: "MBA" },
  { institute_value: "university of madras", ug_degree: "Bachelors Degree" },
  {
    institute_value: "st xaviers college jaipur",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "amity university", ug_degree: "MBA" },
  { institute_value: "university of madras", ug_degree: "MCOM" },
  { institute_value: "kristu jayanti college", ug_degree: "MBA" },
  {
    institute_value: "harcourt butler technological university",
    ug_degree: null,
  },
  {
    institute_value: "dayananda sagar college of engineering",
    ug_degree: null,
  },
  { institute_value: "university of lucknow", ug_degree: null },
  { institute_value: "manipal university", ug_degree: "BSC" },
  { institute_value: "christ university bangalore", ug_degree: "BE" },
  { institute_value: "calcutta university", ug_degree: null },
  { institute_value: "gauhati university", ug_degree: "MCA" },
  {
    institute_value: "indian institute of technology kanpur",
    ug_degree: "MTECH",
  },
  { institute_value: "mumbai university", ug_degree: "MBA" },
  {
    institute_value: "savitribai phule pune university",
    ug_degree: "Masters Degree",
  },
  { institute_value: "mumbai university", ug_degree: "Bachelor of Laws" },
  { institute_value: "indian institute of science", ug_degree: "BE" },
  { institute_value: "indian institute of technology delhi", ug_degree: "MBA" },
  { institute_value: "university of madras", ug_degree: null },
  { institute_value: "ramaiah institute of technology", ug_degree: null },
  { institute_value: "university of jammu", ug_degree: "MCOM" },
  { institute_value: "st xaviers college jaipur", ug_degree: "BCOM" },
  {
    institute_value: "st xaviers college jaipur",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "manipal university", ug_degree: "MBA" },
  { institute_value: "university of lucknow", ug_degree: "BA" },
  { institute_value: "mit school of management", ug_degree: null },
  { institute_value: "ramaiah institute of technology", ug_degree: "BE" },
  { institute_value: "jbims mumbai", ug_degree: null },
  { institute_value: "indian institute of management", ug_degree: "PGDBA" },
  { institute_value: "patna university", ug_degree: null },
  { institute_value: "tezpur university", ug_degree: "MBA" },
  { institute_value: "mulund college of commerce mumbai", ug_degree: "BCOM" },
  { institute_value: "national dairy research institute", ug_degree: null },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "MBA",
  },
  { institute_value: "pondicherry university", ug_degree: null },
  { institute_value: "osmania university", ug_degree: "Bachelor of Laws" },
  {
    institute_value: "indian institute of technology bombay",
    ug_degree: "MTECH",
  },
  { institute_value: "university of lucknow", ug_degree: "BCOM" },
  { institute_value: "indian institute of foreign trade", ug_degree: "MCOM" },
  { institute_value: "gujarat university", ug_degree: "MCOM" },
  { institute_value: "osmania university", ug_degree: "Bachelors Degree" },
  { institute_value: "madurai kamaraj university", ug_degree: "BCOM" },
  { institute_value: "jain university", ug_degree: "MBA" },
  { institute_value: "mumbai university", ug_degree: "MCOM" },
  { institute_value: "coimbatore institute of technology", ug_degree: "MBA" },
  { institute_value: "manipal institute of technology", ug_degree: null },
  { institute_value: "shri ram college of commerce", ug_degree: null },
  { institute_value: "maharshi dayanand university", ug_degree: "BSC" },
  { institute_value: "mount carmel college", ug_degree: null },
  { institute_value: "national law university", ug_degree: "Bachelor of Laws" },
  {
    institute_value: "indian institute of technology kharagpur",
    ug_degree: "BSC",
  },
  { institute_value: "birla institute of technology", ug_degree: "MTECH" },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: "MBA",
  },
  { institute_value: "birla institute of technology", ug_degree: null },
  { institute_value: "manipal institute of technology", ug_degree: "BE" },
  {
    institute_value: "nitte meenakshi institute of technology",
    ug_degree: "BE",
  },
  {
    institute_value: "indian institute of technology kharagpur",
    ug_degree: "BE",
  },
  { institute_value: "techno india university kolkata", ug_degree: null },
  { institute_value: "visvesvaraya technological university", ug_degree: null },
  { institute_value: "bharathidasan university", ug_degree: "MCA" },
  { institute_value: "government college of technology", ug_degree: "BSC" },
  {
    institute_value: "indian institute of technology roorkee",
    ug_degree: "MBA",
  },
  { institute_value: "jawaharlal nehru university", ug_degree: "MTECH" },
  { institute_value: "national institute of technology", ug_degree: "BSC" },
  { institute_value: "national institute of technology", ug_degree: "MTECH" },
  { institute_value: "great lakes institute of management", ug_degree: "MBA" },
  {
    institute_value: "indian institute of technology madras",
    ug_degree: "MBA",
  },
  {
    institute_value: "indian institute of technology kharagpur",
    ug_degree: "MTECH",
  },
  {
    institute_value: "karunya institute of technology and sciences",
    ug_degree: "BSC",
  },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "new horizon college of engineering", ug_degree: "BSC" },
  {
    institute_value: "indian institute of technology bombay",
    ug_degree: "MBA",
  },
  {
    institute_value: "institute of management and technology",
    ug_degree: "BE",
  },
  { institute_value: "christ university", ug_degree: "Masters Degree" },
  {
    institute_value: "jd institute of fashion technology bangalore",
    ug_degree: "BE",
  },
  { institute_value: "the university of burdwan", ug_degree: "BE" },
  {
    institute_value: "indian institute of technology kharagpur",
    ug_degree: "MSC",
  },
  {
    institute_value: "indian institute of technology roorkee",
    ug_degree: "MTECH",
  },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "BSC",
  },
  {
    institute_value: "narsee monjee college of commerce and economics mumbai",
    ug_degree: "BSC",
  },
  { institute_value: "amrita vishwa vidyapeetham", ug_degree: "BE" },
  {
    institute_value: "indian institute of technology bombay",
    ug_degree: "BSC",
  },
  {
    institute_value: "iit bhubaneswar  indian institute of technology",
    ug_degree: "BSC",
  },
  {
    institute_value: "jaypee institute of information technology university",
    ug_degree: "BSC",
  },
  {
    institute_value: "iit ropar  indian institute of technology",
    ug_degree: null,
  },
  { institute_value: "jadavpur university", ug_degree: "BE" },
  { institute_value: "manipal academy of higher education", ug_degree: "MBA" },
  { institute_value: "indian institute of technology madras", ug_degree: "BE" },
  {
    institute_value: "indian institute of technology kanpur",
    ug_degree: "MBA",
  },
  { institute_value: "bharathidasan university", ug_degree: "Masters Degree" },
  { institute_value: "mumbai university", ug_degree: "BSC" },
  { institute_value: "institute of chemical technology", ug_degree: "BE" },
  {
    institute_value: "institute of management and technology",
    ug_degree: "MBA",
  },
  { institute_value: "savitribai phule pune university", ug_degree: "MCA" },
  {
    institute_value: "i.k. gujral punjab technical university",
    ug_degree: null,
  },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "BE",
  },
  { institute_value: "institute of technology and science", ug_degree: "MSC" },
  {
    institute_value: "dayananda sagar college of engineering",
    ug_degree: "BE",
  },
  { institute_value: "jamia hamdard", ug_degree: "MBA" },
  { institute_value: "panjab university", ug_degree: "BE" },
  {
    institute_value: "kalinga institute of industrial technology",
    ug_degree: null,
  },
  { institute_value: "ramaiah institute of technology", ug_degree: "MBA" },
  { institute_value: "madurai kamaraj university", ug_degree: "MCA" },
  { institute_value: "kristu jayanti college", ug_degree: null },
  { institute_value: "sri krishna arts and science college", ug_degree: "BCA" },
  { institute_value: "utkal university", ug_degree: "MBA" },
  { institute_value: "annamalai university", ug_degree: "MSC" },
  { institute_value: "sharda university", ug_degree: "MBA" },
  {
    institute_value: "andhra university visakhapatnam",
    ug_degree: "Masters Degree",
  },
  {
    institute_value: "s.r.m. institute of science and technology",
    ug_degree: null,
  },
  { institute_value: "delhi university", ug_degree: null },
  { institute_value: "jamia millia islamia", ug_degree: "BE" },
  {
    institute_value: "symbiosis institute of business management",
    ug_degree: null,
  },
  {
    institute_value: "christ university bangalore",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "banasthali vidyapith", ug_degree: null },
  { institute_value: "mysore university", ug_degree: "BE" },
  {
    institute_value: "ramaiah college of arts science and commerce",
    ug_degree: "MBA",
  },
  { institute_value: "university of lucknow", ug_degree: "Bachelors Degree" },
  { institute_value: "mysore university", ug_degree: "BCOM" },
  { institute_value: "mumbai university", ug_degree: "BBA" },
  {
    institute_value: "sri krishna college of engineering and technology",
    ug_degree: "BSC",
  },
  { institute_value: "jamia millia islamia", ug_degree: "MBA" },
  { institute_value: "andhra university visakhapatnam", ug_degree: "BE" },
  { institute_value: "asia pacific institute of management", ug_degree: "MBA" },
  { institute_value: "garden city university", ug_degree: "MBA" },
  { institute_value: "bharati vidyapeeth", ug_degree: "MSC" },
  { institute_value: "kakatiya university", ug_degree: "MBA" },
  { institute_value: "vellore institute of technology", ug_degree: null },
  { institute_value: "kerala university", ug_degree: null },
  { institute_value: "amrita vishwa vidyapeetham", ug_degree: "MBA" },
  {
    institute_value: "savitribai phule pune university",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "visvesvaraya technological university",
    ug_degree: "MBA",
  },
  { institute_value: "calicut university", ug_degree: "BE" },
  { institute_value: "new horizon college of engineering", ug_degree: "MBA" },
  { institute_value: "madras christian college", ug_degree: "BCOM" },
  {
    institute_value: "institute of professional excellence and management",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "institute of management studies", ug_degree: null },
  { institute_value: "vivekananda degree college bangalore", ug_degree: "BA" },
  { institute_value: "institute of management studies", ug_degree: "MA" },
  { institute_value: "bharathidasan university", ug_degree: "BCOM" },
  {
    institute_value: "symbiosis institute of business management",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "pes university", ug_degree: "BCOM" },
  { institute_value: "sri venkateswara university", ug_degree: null },
  { institute_value: "mysore university", ug_degree: "MBA" },
  { institute_value: "mumbai university", ug_degree: "BA" },
  {
    institute_value:
      "thapar institute of engineering and technology (deemed-to-be-university)",
    ug_degree: null,
  },
  {
    institute_value: "dayananda sagar college of engineering",
    ug_degree: "BSC",
  },
  { institute_value: "vellore institute of technology", ug_degree: "MBA" },
  { institute_value: "madras christian college", ug_degree: null },
  { institute_value: "madras school of social work", ug_degree: null },
  { institute_value: "mysore university", ug_degree: "Masters Degree" },
  {
    institute_value: "andhra university visakhapatnam",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "calicut university", ug_degree: null },
  { institute_value: "mumbai university", ug_degree: "Masters Degree" },
  { institute_value: "st xaviers college jaipur", ug_degree: null },
  { institute_value: "pondicherry university", ug_degree: "MBA" },
  { institute_value: "govindam business school new delhi", ug_degree: "MBA" },
  { institute_value: "mumbai university", ug_degree: "Bachelors Degree" },
  { institute_value: "international management institute", ug_degree: null },
  { institute_value: "kakatiya university", ug_degree: "Bachelors Degree" },
  { institute_value: "bharathiar university", ug_degree: "Bachelor of Laws" },
  { institute_value: "osmania university", ug_degree: "Masters Degree" },
  { institute_value: "university of madras", ug_degree: "BA" },
  { institute_value: "university of lucknow", ug_degree: "MBA" },
  { institute_value: "indian institute of management", ug_degree: "BCOM" },
  {
    institute_value: "national law school of india university",
    ug_degree: "BCOM",
  },
  { institute_value: "madurai kamaraj university", ug_degree: "BE" },
  { institute_value: "aligarh muslim university", ug_degree: null },
  {
    institute_value: "dr babasaheb ambedkar marathwada university",
    ug_degree: "MBA",
  },
  { institute_value: "sardar patel university", ug_degree: "MBA" },
  { institute_value: "tata institute of social sciences", ug_degree: "MBA" },
  { institute_value: "andhra university visakhapatnam", ug_degree: "MBA" },
  {
    institute_value: "christ university bangalore",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "university of lucknow", ug_degree: "Masters Degree" },
  { institute_value: "kakatiya university", ug_degree: null },
  { institute_value: "pes university", ug_degree: "MBA" },
  { institute_value: "christ university bangalore", ug_degree: "BSC" },
  { institute_value: "sns college of technology", ug_degree: "MBA" },
  { institute_value: "sri venkateswara university", ug_degree: "MBA" },
  {
    institute_value: "national law school of india university",
    ug_degree: "Master of Laws",
  },
  { institute_value: "loyola college chennai", ug_degree: null },
  { institute_value: "symbiosis international", ug_degree: "Bachelor of Laws" },
  { institute_value: "amity university lucknow", ug_degree: null },
  { institute_value: "christ college pune", ug_degree: "MBA" },
  { institute_value: "loyola college", ug_degree: null },
  { institute_value: "banasthali vidyapith", ug_degree: "MBA" },
  { institute_value: "periyar university", ug_degree: "MBA" },
  {
    institute_value: "manonmaniam sundaranar university tirunelveli",
    ug_degree: "MBA",
  },
  { institute_value: "university of jammu", ug_degree: "MBA" },
  {
    institute_value: "maharaja sayajirao university of baroda",
    ug_degree: null,
  },
  { institute_value: "central university of rajasthan", ug_degree: "MBA" },
  { institute_value: "utkal university", ug_degree: null },
  { institute_value: "srm engineering college kanchipuram", ug_degree: "MBA" },
  { institute_value: "bharathidasan university", ug_degree: "MSC" },
  { institute_value: "mysore university", ug_degree: "BSC" },
  { institute_value: "utkal university", ug_degree: "BE" },
  { institute_value: "alliance university", ug_degree: null },
  { institute_value: "nmkrv college for women bangalore", ug_degree: "BCOM" },
  {
    institute_value: "lady shri ram college for women",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "university of hyderabad", ug_degree: "MA" },
  { institute_value: "bharathiar university", ug_degree: null },
  { institute_value: "osmania university", ug_degree: "MCA" },
  { institute_value: "goa institute of management", ug_degree: "MBA" },
  { institute_value: "utkal university", ug_degree: "BCOM" },
  { institute_value: "calcutta university", ug_degree: "BSC" },
  {
    institute_value: "icfai foundation for higher education hyderabad",
    ug_degree: null,
  },
  {
    institute_value: "narsee monjee college of commerce and economics mumbai",
    ug_degree: "BCOM",
  },
  { institute_value: "central university of rajasthan", ug_degree: "BE" },
  { institute_value: "dy patil university navi mumbai", ug_degree: "MBA" },
  { institute_value: "rajagiri college of social sciences", ug_degree: "MA" },
  { institute_value: "andhra university visakhapatnam", ug_degree: "BA" },
  { institute_value: "sona college of technology salem", ug_degree: "MBA" },
  { institute_value: "institute of technology and science", ug_degree: "BSC" },
  { institute_value: "jawaharlal nehru university", ug_degree: "BSC" },
  { institute_value: "christ university bangalore", ug_degree: "BCA" },
  { institute_value: "alliance university", ug_degree: "BSC" },
  {
    institute_value: "ramnarain ruia autonomous college mumbai",
    ug_degree: null,
  },
  { institute_value: "amrita vishwa vidyapeetham", ug_degree: null },
  { institute_value: "university of hyderabad", ug_degree: null },
  { institute_value: "vssd college kanpur", ug_degree: "MBA" },
  {
    institute_value: "visvesvaraya technological university",
    ug_degree: "Masters Degree",
  },
  { institute_value: "institute of medical sciences", ug_degree: null },
  { institute_value: "sri venkateswara college", ug_degree: "BCOM" },
  {
    institute_value: "indian institute of technology kharagpur",
    ug_degree: "BCOM",
  },
  {
    institute_value: "hr college of commerce and economics mumbai",
    ug_degree: null,
  },
  { institute_value: "mysore university", ug_degree: "BBA" },
  { institute_value: "periyar university", ug_degree: null },
  {
    institute_value: "indian institute of management bangalore",
    ug_degree: null,
  },
  { institute_value: "pondicherry university", ug_degree: "MA" },
  { institute_value: "indian institute of technology delhi", ug_degree: "BE" },
  { institute_value: "jawaharlal nehru university", ug_degree: "BE" },
  {
    institute_value: "cochin university of science and technology",
    ug_degree: "MTECH",
  },
  { institute_value: "presidency university kolkata", ug_degree: "MCA" },
  { institute_value: "galgotias university", ug_degree: "MCA" },
  {
    institute_value: "iit guwahati  indian institute of technology",
    ug_degree: "BSC",
  },
  { institute_value: "christ university bangalore", ug_degree: "MCOM" },
  { institute_value: "bharati vidyapeeth", ug_degree: "BE" },
  { institute_value: "presidency university kolkata", ug_degree: null },
  { institute_value: "calcutta university", ug_degree: "MBA" },
  { institute_value: "university of hyderabad", ug_degree: "MTECH" },
  { institute_value: "lovely professional university", ug_degree: "BSC" },
  {
    institute_value: "indian institute of technology roorkee",
    ug_degree: "BSC",
  },
  {
    institute_value: "shaheed sukhdev college of business studies",
    ug_degree: null,
  },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "BSC",
  },
  { institute_value: "national institute of management", ug_degree: null },
  { institute_value: "annamalai university", ug_degree: "BE" },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: "MSC",
  },
  {
    institute_value: "sambhram academy of management studies",
    ug_degree: "BCOM",
  },
  {
    institute_value: "bharati vidyapeeth institute of management and research",
    ug_degree: "MBA",
  },
  { institute_value: "mit school of management", ug_degree: "MBA" },
  { institute_value: "asansol engineering college", ug_degree: null },
  { institute_value: "indian institute of management", ug_degree: "MA" },
  { institute_value: "calicut university", ug_degree: "BSC" },
  {
    institute_value: "ramaiah institute of technology",
    ug_degree: "Masters Degree",
  },
  { institute_value: "jai narain vyas university", ug_degree: null },
  { institute_value: "itm university gwalior", ug_degree: "BE" },
  {
    institute_value: "indian institute of technology kanpur",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "madurai kamaraj university", ug_degree: "BA" },
  { institute_value: "hansraj college", ug_degree: "MSC" },
  { institute_value: "lovely professional university", ug_degree: "MSC" },
  { institute_value: "gauhati university", ug_degree: "Masters Degree" },
  {
    institute_value: "iit hyderabad  indian institute of technology",
    ug_degree: "BSC",
  },
  { institute_value: "indian institute of technology kanpur", ug_degree: "BE" },
  {
    institute_value: "indian institute of technology roorkee",
    ug_degree: "BE",
  },
  {
    institute_value: "yashwantrao chavan maharashtra open university",
    ug_degree: "BE",
  },
  { institute_value: "bharathidasan university", ug_degree: "BE" },
  { institute_value: "gujarat university", ug_degree: "BCA" },
  {
    institute_value: "institute of management education group of colleges",
    ug_degree: "MBA",
  },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "PGDBA",
  },
  { institute_value: "fergusson college (autonomous)", ug_degree: null },
  {
    institute_value: "iit patna  indian institute of technology",
    ug_degree: "BE",
  },
  { institute_value: "sri venkateswara university", ug_degree: "BE" },
  {
    institute_value: "institute of management and technology",
    ug_degree: null,
  },
  { institute_value: "national college", ug_degree: "BCOM" },
  { institute_value: "jain university", ug_degree: "BBA" },
  {
    institute_value: "university of agricultural sciences bangalore",
    ug_degree: null,
  },
  { institute_value: "gargi college", ug_degree: null },
  { institute_value: "indian institute of technology delhi", ug_degree: "MSC" },
  { institute_value: "poddar international college jaipur", ug_degree: "MBA" },
  { institute_value: "nirma university", ug_degree: null },
  { institute_value: "galgotias university", ug_degree: "Masters Degree" },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "MSC",
  },
  { institute_value: "ranchi university ranchi", ug_degree: null },
  {
    institute_value: "iit indore  indian institute of technology",
    ug_degree: "BSC",
  },
  { institute_value: "university of madras", ug_degree: "BSC" },
  { institute_value: "christ university", ug_degree: "BSC" },
  {
    institute_value: "veermata jijabai technological institute",
    ug_degree: "BE",
  },
  {
    institute_value: "indian institute of foreign trade",
    ug_degree: "Masters Degree",
  },
  {
    institute_value: "government institute of science nagpur",
    ug_degree: "BSC",
  },
  { institute_value: "fergusson college (autonomous)", ug_degree: "BSC" },
  {
    institute_value: "cochin university of science and technology",
    ug_degree: null,
  },
  { institute_value: "thiagarajar college", ug_degree: "BE" },
  { institute_value: "kurukshetra university", ug_degree: "MSC" },
  { institute_value: "garden city university", ug_degree: null },
  { institute_value: "shobhit university meerut", ug_degree: "BSC" },
  { institute_value: "upes", ug_degree: "MBA" },
  {
    institute_value:
      "international institute of information technology hyderabad",
    ug_degree: "BSC",
  },
  { institute_value: "bharathiar university", ug_degree: "MA" },
  {
    institute_value: "bangalore medical college and research institute",
    ug_degree: null,
  },
  { institute_value: "manipal academy of higher education", ug_degree: null },
  { institute_value: "indian maritime university", ug_degree: null },
  {
    institute_value: "symbiosis institute of media and communication",
    ug_degree: null,
  },
  { institute_value: "barkatullah university", ug_degree: null },
  { institute_value: "manipal university", ug_degree: "BBA" },
  { institute_value: "maharshi dayanand university", ug_degree: "MBA" },
  { institute_value: "madurai kamaraj university", ug_degree: "BSC" },
  { institute_value: "kerala university", ug_degree: "MBA" },
  { institute_value: "central university of rajasthan", ug_degree: "BCOM" },
  { institute_value: "st xaviers college jaipur", ug_degree: "CA" },
  { institute_value: "indian institute of foreign trade", ug_degree: "BCOM" },
  {
    institute_value: "visvesvaraya technological university",
    ug_degree: "MCA",
  },
  { institute_value: "hansraj college", ug_degree: "BCOM" },
  {
    institute_value: "shobhit university meerut",
    ug_degree: "Bachelor of Laws",
  },
  {
    institute_value: "vivekananda degree college bangalore",
    ug_degree: "BCOM",
  },
  {
    institute_value: "symbiosis institute of business management",
    ug_degree: "Masters Degree",
  },
  { institute_value: "rkdf university bhopal", ug_degree: "BE" },
  {
    institute_value: "national institute of technology tiruchirappalli",
    ug_degree: null,
  },
  { institute_value: "vellore institute of technology", ug_degree: "BSC" },
  { institute_value: "sri krishna college of technology", ug_degree: null },
  { institute_value: "lovely professional university", ug_degree: "MBA" },
  { institute_value: "kerala university", ug_degree: "MA" },
  {
    institute_value: "international institute of information technology",
    ug_degree: "MSC",
  },
  { institute_value: "kakatiya university", ug_degree: "BSC" },
  { institute_value: "mahatma gandhi university kottayam", ug_degree: null },
  { institute_value: "indian institute of science", ug_degree: "MTECH" },
  {
    institute_value: "dayananda sagar college of engineering",
    ug_degree: "MSC",
  },
  { institute_value: "chandigarh university", ug_degree: null },
  { institute_value: "kongu engineering college", ug_degree: "MBA" },
  { institute_value: "manipal university", ug_degree: "Masters Degree" },
  { institute_value: "central university of rajasthan", ug_degree: "BA" },
  { institute_value: "calcutta university", ug_degree: "Bachelors Degree" },
  {
    institute_value: "kumaraguru college of technology",
    ug_degree: "Masters Degree",
  },
  { institute_value: "sri venkateswara college", ug_degree: null },
  { institute_value: "sona college of technology salem", ug_degree: "BE" },
  {
    institute_value: "sri krishna college of technology",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "coimbatore institute of technology", ug_degree: "BSC" },
  { institute_value: "sri krishna college of technology", ug_degree: "MCA" },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "BCOM",
  },
  {
    institute_value: "sri krishna college of engineering and technology",
    ug_degree: "BE",
  },
  { institute_value: "anna university", ug_degree: "Bachelors Degree" },
  { institute_value: "sns college of technology", ug_degree: "MSC" },
  {
    institute_value: "sri krishna college of engineering and technology",
    ug_degree: "MBA",
  },
  { institute_value: "government college of technology", ug_degree: null },
  {
    institute_value: "central university of punjab",
    ug_degree: "Masters Degree",
  },
  { institute_value: "bharathiar university", ug_degree: "MSC" },
  {
    institute_value: "institute of management and technology",
    ug_degree: "MCA",
  },
  { institute_value: "christ university", ug_degree: "BCOM" },
  { institute_value: "psg college of arts and science", ug_degree: "MSC" },
  { institute_value: "siddaganga institute of technology", ug_degree: "BE" },
  { institute_value: "upes", ug_degree: "BSC" },
  { institute_value: "psg college of arts and science", ug_degree: "BSC" },
  { institute_value: "rathinam college of arts and science", ug_degree: "BSC" },
  { institute_value: "psgr krishnammal college for women", ug_degree: "BSC" },
  {
    institute_value: "sri krishna college of engineering and technology",
    ug_degree: "BCA",
  },
  {
    institute_value: "manonmaniam sundaranar university tirunelveli",
    ug_degree: "BA",
  },
  { institute_value: "bharathiar university", ug_degree: "BSC" },
  { institute_value: "bharathiar university", ug_degree: "MCA" },
  {
    institute_value:
      "sri ramakrishna mission vidyalaya college of arts and science",
    ug_degree: "BA",
  },
  {
    institute_value: "ludhiana college of engineering and technology ludhiana",
    ug_degree: "BE",
  },
  { institute_value: "periyar university", ug_degree: "Masters Degree" },
  {
    institute_value: "cochin university of science and technology",
    ug_degree: "MBA",
  },
  {
    institute_value: "madurai kamaraj university",
    ug_degree: "Masters Degree",
  },
  { institute_value: "farook college kozhikode", ug_degree: null },
  { institute_value: "kongu engineering college", ug_degree: "BE" },
  { institute_value: "cms college kottayam", ug_degree: "MBA" },
  { institute_value: "calicut university", ug_degree: "Bachelors Degree" },
  { institute_value: "psg college of arts and science", ug_degree: "MA" },
  {
    institute_value: "bharathidasan university",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "psg college of arts and science", ug_degree: "BBA" },
  {
    institute_value: "coimbatore institute of technology",
    ug_degree: "Masters Degree",
  },
  { institute_value: "lady shri ram college for women", ug_degree: "BCOM" },
  { institute_value: "psg college of arts and science", ug_degree: "BCOM" },
  { institute_value: "presidency college", ug_degree: null },
  { institute_value: "kongu engineering college", ug_degree: "BCA" },
  { institute_value: "st xaviers college jaipur", ug_degree: "BSC" },
  { institute_value: "ranchi university ranchi", ug_degree: "BCOM" },
  { institute_value: "national college", ug_degree: "MCA" },
  { institute_value: "delhi college of arts and commerce", ug_degree: null },
  {
    institute_value: "national institute of industrial engineering mumbai",
    ug_degree: null,
  },
  { institute_value: "madurai kamaraj university", ug_degree: "MA" },
  {
    institute_value:
      "sam higginbottom university of agriculture technology and sciences",
    ug_degree: null,
  },
  { institute_value: "bharathiar university", ug_degree: "Bachelors Degree" },
  { institute_value: "loyola college", ug_degree: "Bachelors Degree" },
  { institute_value: "patna college patna", ug_degree: "BSC" },
  { institute_value: "psgr krishnammal college for women", ug_degree: null },
  {
    institute_value: "dayananda sagar college of engineering",
    ug_degree: "BCOM",
  },
  { institute_value: "bharathiar university", ug_degree: "BCOM" },
  { institute_value: "calicut university", ug_degree: "BCOM" },
  {
    institute_value: "sri ramakrishna college of arts and science",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "psg college of arts and science",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "shobhit university meerut",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "annamalai university", ug_degree: "MA" },
  { institute_value: "central university of rajasthan", ug_degree: "BSC" },
  {
    institute_value: "i.k. gujral punjab technical university",
    ug_degree: "BE",
  },
  { institute_value: "andhra university visakhapatnam", ug_degree: "BSC" },
  {
    institute_value: "institute of hotel management aurangabad",
    ug_degree: "BSC",
  },
  { institute_value: "tilak maharashtra vidyapeeth", ug_degree: null },
  {
    institute_value: "prince shri venkateshwara padmavathy engineering college",
    ug_degree: "BE",
  },
  { institute_value: "university of jammu", ug_degree: "BCOM" },
  { institute_value: "loyola college", ug_degree: "MSC" },
  {
    institute_value: "ahmedabad university ahmedabad",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "bharathidasan university", ug_degree: "MA" },
  { institute_value: "tata institute of social sciences", ug_degree: "BSC" },
  { institute_value: "gujarat university", ug_degree: "BSC" },
  {
    institute_value: "justice basheer ahmed sayeed  college for women",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "kurukshetra university", ug_degree: "Bachelors Degree" },
  { institute_value: "galgotias university", ug_degree: "BSC" },
  { institute_value: "guru nanak dev university", ug_degree: null },
  { institute_value: "the american college", ug_degree: "BCOM" },
  { institute_value: "loyola college", ug_degree: "BCOM" },
  { institute_value: "maharaja agrasen college", ug_degree: "BCOM" },
  {
    institute_value: "indira gandhi national open university",
    ug_degree: "MA",
  },
  { institute_value: "daulat ram college", ug_degree: null },
  {
    institute_value: "ramakrishna mission vivekananda college chennai",
    ug_degree: "BBA",
  },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "BBA",
  },
  { institute_value: "gargi college", ug_degree: "Bachelors Degree" },
  { institute_value: "patna college patna", ug_degree: null },
  {
    institute_value: "cms college of science and commerce coimbatore",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "utkal university", ug_degree: "BA" },
  { institute_value: "ranchi university ranchi", ug_degree: "BSC" },
  { institute_value: "dav college", ug_degree: null },
  { institute_value: "gujarat university", ug_degree: "BBA" },
  { institute_value: "amity university", ug_degree: "BE" },
  { institute_value: "ethiraj college for women", ug_degree: "BBA" },
  { institute_value: "bharati vidyapeeth", ug_degree: "BBA" },
  { institute_value: "tata institute of social sciences", ug_degree: "BE" },
  { institute_value: "deen dayal upadhyaya college", ug_degree: "BCOM" },
  {
    institute_value: "symbiosis institute of media and communication",
    ug_degree: "MBA",
  },
  { institute_value: "hindu college", ug_degree: null },
  {
    institute_value: "indian institute of management",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "savitribai phule pune university",
    ug_degree: "Master of Laws",
  },
  { institute_value: "lady shri ram college for women", ug_degree: null },
  { institute_value: "dyal singh college", ug_degree: null },
  {
    institute_value: "sri guru gobind singh college of commerce",
    ug_degree: "BCOM",
  },
  {
    institute_value: "chaudhary charan singh haryana agricultural university",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "banaras hindu university", ug_degree: "MTECH" },
  { institute_value: "mount carmel college", ug_degree: "MBA" },
  { institute_value: "calcutta university", ug_degree: "Bachelor of Laws" },
  {
    institute_value: "maharaja sayajirao university of baroda",
    ug_degree: "BE",
  },
  { institute_value: "banaras hindu university", ug_degree: "MBA" },
  {
    institute_value: "punjab agricultural university ludhiana",
    ug_degree: null,
  },
  { institute_value: "hindu college", ug_degree: "BCOM" },
  { institute_value: "hislop college nagpur", ug_degree: "BA" },
  { institute_value: "jamia millia islamia", ug_degree: "MTECH" },
  { institute_value: "madurai kamaraj university", ug_degree: "BCA" },
  {
    institute_value: "chaudhary charan singh haryana agricultural university",
    ug_degree: null,
  },
  { institute_value: "tamil nadu agricultural university", ug_degree: "BE" },
  { institute_value: "mumbai university", ug_degree: "MCA" },
  { institute_value: "sri venkateswara university", ug_degree: "MCA" },
  { institute_value: "panjab university", ug_degree: "BCOM" },
  { institute_value: "dav college", ug_degree: "Bachelors Degree" },
  { institute_value: "pvkn govt degree college", ug_degree: "BCOM" },
  {
    institute_value: "narsee monjee college of commerce and economics mumbai",
    ug_degree: null,
  },
  { institute_value: "savitribai phule pune university", ug_degree: "MCOM" },
  {
    institute_value: "maharani lakshmi ammanni college for women",
    ug_degree: null,
  },
  { institute_value: "maharaja agrasen college", ug_degree: "BSC" },
  { institute_value: "kerala university", ug_degree: "BSC" },
  {
    institute_value: "sri krishna arts and science college",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "rashtrasant tukadoji maharaj nagpur university",
    ug_degree: "BE",
  },
  {
    institute_value: "jaypee institute of information technology university",
    ug_degree: "BE",
  },
  { institute_value: "sardar patel institute of technology", ug_degree: "BE" },
  { institute_value: "manipal institute of technology", ug_degree: "BSC" },
  { institute_value: "presidency college", ug_degree: "Bachelors Degree" },
  {
    institute_value: "karunya institute of technology and sciences",
    ug_degree: "MTECH",
  },
  {
    institute_value: "sri jayachamarajendra college of engineering",
    ug_degree: "BE",
  },
  {
    institute_value: "iit ropar  indian institute of technology",
    ug_degree: "MSC",
  },
  {
    institute_value:
      "thapar institute of engineering and technology (deemed-to-be-university)",
    ug_degree: "BE",
  },
  { institute_value: "guru nanak dev university", ug_degree: "BE" },
  { institute_value: "jain university", ug_degree: "BA" },
  { institute_value: "kerala university", ug_degree: "BE" },
  {
    institute_value: "iit jodhpur  indian institute of technology",
    ug_degree: "MSC",
  },
  { institute_value: "galgotias university", ug_degree: "MTECH" },
  {
    institute_value: "indian institute of technology bombay",
    ug_degree: "Masters Degree",
  },
  { institute_value: "integral university lucknow", ug_degree: "BSC" },
  {
    institute_value: "brihan maharashtra college of commerce",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value:
      "j.c. bose university of science and technology ymca faridabad",
    ug_degree: null,
  },
  { institute_value: "shri shikshayatan college kolkata", ug_degree: "BCOM" },
  {
    institute_value: "vasantrao naik mahavidyalaya aurangabad",
    ug_degree: null,
  },
  {
    institute_value: "sri sairam engineering college chennai",
    ug_degree: "BE",
  },
  { institute_value: "institute of technology and science", ug_degree: "BCA" },
  { institute_value: "miranda house", ug_degree: "BSC" },
  { institute_value: "mahatma gandhi university", ug_degree: null },
  {
    institute_value: "cochin university of science and technology",
    ug_degree: "BE",
  },
  { institute_value: "vellore institute of technology", ug_degree: "MTECH" },
  { institute_value: "mount carmel college", ug_degree: "BCOM" },
  { institute_value: "jss academy of technical education", ug_degree: null },
  { institute_value: "aligarh muslim university", ug_degree: "MTECH" },
  { institute_value: "bharathidasan university", ug_degree: "BSC" },
  { institute_value: "deen dayal upadhyaya college", ug_degree: null },
  {
    institute_value: "iit jodhpur  indian institute of technology",
    ug_degree: "BSC",
  },
  {
    institute_value: "university college of engineering osmania university",
    ug_degree: "BE",
  },
  { institute_value: "calcutta university", ug_degree: "Masters Degree" },
  { institute_value: "ahmedabad university ahmedabad", ug_degree: "BCOM" },
  {
    institute_value: "i.k. gujral punjab technical university",
    ug_degree: "BSC",
  },
  { institute_value: "dy patil university navi mumbai", ug_degree: null },
  { institute_value: "jamia millia islamia", ug_degree: "Bachelors Degree" },
  { institute_value: "government arts college", ug_degree: "BSC" },
  {
    institute_value: "iit indore  indian institute of technology",
    ug_degree: null,
  },
  {
    institute_value: "mahatma gandhi kashi vidyapith",
    ug_degree: "Masters Degree",
  },
  { institute_value: "sharda university", ug_degree: "BBA" },
  { institute_value: "jesus and mary college", ug_degree: "Bachelors Degree" },
  { institute_value: "indian institute of management", ug_degree: "CA" },
  { institute_value: "banasthali vidyapith", ug_degree: "BSC" },
  {
    institute_value: "institute of management studies",
    ug_degree: "Masters Degree",
  },
  {
    institute_value:
      "thapar institute of engineering and technology (deemed-to-be-university)",
    ug_degree: "MTECH",
  },
  {
    institute_value: "guru gobind singh indraprastha university",
    ug_degree: "MTECH",
  },
  {
    institute_value: "cochin university of science and technology",
    ug_degree: "BSC",
  },
  {
    institute_value: "ethiraj college for women",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "ramanujan college", ug_degree: null },
  { institute_value: "gargi college", ug_degree: "BCOM" },
  { institute_value: "osmania university", ug_degree: "BA" },
  { institute_value: "maharshi dayanand university", ug_degree: "MCA" },
  { institute_value: "barkatullah university", ug_degree: "MBA" },
  {
    institute_value: "asia pacific institute of management",
    ug_degree: "Masters Degree",
  },
  { institute_value: "university of lucknow", ug_degree: "BSC" },
  {
    institute_value: "yashwantrao chavan maharashtra open university",
    ug_degree: "MBA",
  },
  {
    institute_value: "maharaja sayajirao university of baroda",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "christ college pune", ug_degree: null },
  { institute_value: "patna college patna", ug_degree: "BCOM" },
  { institute_value: "holy cross college tiruchirappalli", ug_degree: "BSC" },
  { institute_value: "rajagiri college of social sciences", ug_degree: null },
  { institute_value: "kerala university", ug_degree: "BCOM" },
  {
    institute_value: "maharaja sayajirao university of baroda",
    ug_degree: "MBA",
  },
  { institute_value: "hansraj college", ug_degree: "Bachelors Degree" },
  { institute_value: "calcutta university", ug_degree: "BA" },
  { institute_value: "st xaviers college jaipur", ug_degree: "MBA" },
  { institute_value: "university of madras", ug_degree: "MSC" },
  { institute_value: "dr.g.r.damodaran college of science", ug_degree: "BSC" },
  { institute_value: "aims institutes bangalore", ug_degree: null },
  { institute_value: "calicut university", ug_degree: "MCOM" },
  { institute_value: "annamalai university", ug_degree: "Master of Laws" },
  { institute_value: "mumbai university", ug_degree: "MA" },
  { institute_value: "university of madras", ug_degree: "BCOM" },
  { institute_value: "anna adarsh college for women chennai", ug_degree: null },
  {
    institute_value: "chaudhary charan singh university",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "sacred heart college", ug_degree: "Bachelors Degree" },
  { institute_value: "annamalai university", ug_degree: "Bachelors Degree" },
  {
    institute_value: "dr babasaheb ambedkar marathwada university",
    ug_degree: "BA",
  },
  { institute_value: "jamia millia islamia", ug_degree: "BCOM" },
  { institute_value: "national college", ug_degree: null },
  { institute_value: "christ college rajkot", ug_degree: "BCOM" },
  { institute_value: "utkal university", ug_degree: "Bachelors Degree" },
  { institute_value: "gauhati university", ug_degree: "BSC" },
  { institute_value: "birla institute of technology", ug_degree: "BBA" },
  { institute_value: "kerala university", ug_degree: "BA" },
  {
    institute_value: "calicut university thenhipalem malapuram",
    ug_degree: "BSC",
  },
  { institute_value: "alagappa university", ug_degree: "BCOM" },
  {
    institute_value: "sri jayachamarajendra college of engineering",
    ug_degree: "MSC",
  },
  { institute_value: "mumbai university", ug_degree: "MSC" },
  { institute_value: "jis college of engineering", ug_degree: "BBA" },
  { institute_value: "vivekananda degree college bangalore", ug_degree: "BSC" },
  { institute_value: "symbiosis law school", ug_degree: "Bachelor of Laws" },
  {
    institute_value: "visvesvaraya technological university",
    ug_degree: "BSC",
  },
  { institute_value: "mysore university", ug_degree: "Bachelors Degree" },
  { institute_value: "rajeev gandhi college bhopal", ug_degree: "BBA" },
  { institute_value: "bjb autonomous college", ug_degree: "BSC" },
  { institute_value: "christ university bangalore", ug_degree: "BA" },
  {
    institute_value: "fergusson college (autonomous)",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "maharaja sayajirao university of baroda",
    ug_degree: "BSC",
  },
  { institute_value: "alliance university", ug_degree: "BCOM" },
  { institute_value: "annamalai university", ug_degree: "Masters Degree" },
  { institute_value: "administrative management college", ug_degree: "MBA" },
  { institute_value: "gujarat university", ug_degree: "Masters Degree" },
  {
    institute_value: "ranchi university ranchi",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "bjb autonomous college", ug_degree: "MBA" },
  { institute_value: "presidency college", ug_degree: "BSC" },
  { institute_value: "maharshi dayanand university", ug_degree: "BBA" },
  { institute_value: "meenakshi college for women chennai", ug_degree: null },
  { institute_value: "bharati vidyapeeth", ug_degree: "Bachelor of Laws" },
  {
    institute_value: "aligarh muslim university",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "sardar patel institute of technology", ug_degree: null },
  { institute_value: "badruka college of commerce and arts", ug_degree: null },
  { institute_value: "chandigarh university", ug_degree: "MBA" },
  { institute_value: "tecnia institute of advanced studies", ug_degree: "MBA" },
  { institute_value: "ramakrishna mission vidyamandira", ug_degree: null },
  { institute_value: "mysore university", ug_degree: "MCA" },
  { institute_value: "ibmr business school gurgaon", ug_degree: "MBA" },
  {
    institute_value: "jd institute of fashion technology bangalore",
    ug_degree: "MTECH",
  },
  {
    institute_value: "ramnarain ruia autonomous college mumbai",
    ug_degree: "BCA",
  },
  { institute_value: "jss academy of technical education", ug_degree: "BSC" },
  { institute_value: "bharathiar university", ug_degree: "BBA" },
  { institute_value: "christ church college", ug_degree: null },
  { institute_value: "fergusson college (autonomous)", ug_degree: "BA" },
  { institute_value: "anna university", ug_degree: "MSC" },
  {
    institute_value: "ra podar college of commerce and economics mumbai",
    ug_degree: null,
  },
  {
    institute_value: "jd institute of fashion technology bangalore",
    ug_degree: null,
  },
  {
    institute_value: "veermata jijabai technological institute",
    ug_degree: null,
  },
  { institute_value: "gauhati university", ug_degree: null },
  { institute_value: "st. aloysius college", ug_degree: "BCOM" },
  { institute_value: "university of madras", ug_degree: "Masters Degree" },
  {
    institute_value: "central university of rajasthan",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "institute of management studies", ug_degree: "BBA" },
  { institute_value: "shri shikshayatan college kolkata", ug_degree: "BA" },
  { institute_value: "guru nanak college", ug_degree: null },
  { institute_value: "gandhi engineering college", ug_degree: "BSC" },
  {
    institute_value: "iit guwahati  indian institute of technology",
    ug_degree: "MTECH",
  },
  {
    institute_value: "sanjay gandhi postgraduate institute of medical sciences",
    ug_degree: null,
  },
  { institute_value: "stella maris college for women", ug_degree: "BCOM" },
  {
    institute_value: "indian institute of technology roorkee",
    ug_degree: "MSC",
  },
  { institute_value: "sri venkateswara university", ug_degree: "BBA" },
  { institute_value: "icfai business school", ug_degree: "PGDBA" },
  {
    institute_value: "jai narain vyas university",
    ug_degree: "Masters Degree",
  },
  { institute_value: "t john college", ug_degree: "MBA" },
  {
    institute_value: "badruka college of commerce and arts",
    ug_degree: "BCOM",
  },
  {
    institute_value: "dayananda sagar college of engineering",
    ug_degree: "BBA",
  },
  { institute_value: "kakatiya university", ug_degree: "MTECH" },
  { institute_value: "university of kashmir", ug_degree: "BCOM" },
  {
    institute_value: "international management institute",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "indraprastha college for women", ug_degree: "BCOM" },
  {
    institute_value: "international institute of hotel management",
    ug_degree: null,
  },
  {
    institute_value: "veermata jijabai technological institute",
    ug_degree: "BSC",
  },
  { institute_value: "pondicherry engineering college", ug_degree: "BE" },
  { institute_value: "jss academy of technical education", ug_degree: "BE" },
  {
    institute_value: "gujarat arts and commerce college ahmedabad",
    ug_degree: "BSC",
  },
  { institute_value: "pes university", ug_degree: null },
  {
    institute_value: "narsee monjee institute of management studies",
    ug_degree: "Masters Degree",
  },
  {
    institute_value: "university of petroleum and energy studies",
    ug_degree: "MBA",
  },
  { institute_value: "christ university bangalore", ug_degree: "MCA" },
  {
    institute_value: "chaudhary charan singh haryana agricultural university",
    ug_degree: "BCOM",
  },
  { institute_value: "institute of chemical technology", ug_degree: null },
  {
    institute_value: "shaheed sukhdev college of business studies",
    ug_degree: "BBA",
  },
  { institute_value: "amity school of fashion technology", ug_degree: null },
  { institute_value: "banaras hindu university", ug_degree: "BSC" },
  {
    institute_value: "jai narain vyas university",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "university of delhi", ug_degree: "CA" },
  { institute_value: "university of kashmir", ug_degree: "BSC" },
  { institute_value: "bishop heber college", ug_degree: null },
  { institute_value: "sacred heart college", ug_degree: null },
  { institute_value: "jyoti nivas college", ug_degree: "BCOM" },
  {
    institute_value: "shri ram college of commerce",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "jd institute of fashion technology bangalore",
    ug_degree: "BSC",
  },
  { institute_value: "university of madras chennai", ug_degree: "BSC" },
  { institute_value: "the american college", ug_degree: null },
  { institute_value: "christ university", ug_degree: "Bachelors Degree" },
  { institute_value: "amity university lucknow", ug_degree: "BCOM" },
  { institute_value: "seshadripuram college bangalore", ug_degree: "BCOM" },
  {
    institute_value: "iit guwahati  indian institute of technology",
    ug_degree: "BE",
  },
  { institute_value: "st xaviers college jaipur", ug_degree: "MSC" },
  { institute_value: "sri venkateswara university", ug_degree: "BSC" },
  { institute_value: "kerala university", ug_degree: "Bachelors Degree" },
  {
    institute_value: "sies college of arts science and commerce",
    ug_degree: null,
  },
  { institute_value: "sacred heart college (autonomous)", ug_degree: "BCOM" },
  { institute_value: "jaipur national university", ug_degree: "BE" },
  { institute_value: "annamalai university", ug_degree: "BBA" },
  { institute_value: "annamalai university", ug_degree: "BA" },
  {
    institute_value: "kongunadu arts and science college coimbatore",
    ug_degree: "BBA",
  },
  {
    institute_value: "national institute of technology tiruchirappalli",
    ug_degree: "BE",
  },
  { institute_value: "psgr krishnammal college for women", ug_degree: "BCOM" },
  { institute_value: "elphinstone college mumbai", ug_degree: null },
  {
    institute_value: "chaudhary charan singh haryana agricultural university",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "pondicherry engineering college", ug_degree: "MTECH" },
  { institute_value: "sri krishna college of technology", ug_degree: "BSC" },
  {
    institute_value: "guru nanak dev university",
    ug_degree: "Bachelor of Laws",
  },
  { institute_value: "indraprastha college for women", ug_degree: null },
  { institute_value: "scottish church college", ug_degree: "Bachelors Degree" },
  { institute_value: "jain university", ug_degree: "BCOM" },
  { institute_value: "dg vaishnav college", ug_degree: null },
  { institute_value: "garden city university", ug_degree: "Bachelors Degree" },
  { institute_value: "jadavpur university", ug_degree: "BBA" },
  {
    institute_value: "maharaja agrasen college",
    ug_degree: "Bachelors Degree",
  },
  { institute_value: "aligarh muslim university", ug_degree: "BCOM" },
  { institute_value: "aligarh muslim university", ug_degree: "Masters Degree" },
  { institute_value: "jadavpur university", ug_degree: "Bachelors Degree" },
  { institute_value: "osmania university", ug_degree: "MCOM" },
  { institute_value: "maharshi dayanand university", ug_degree: "BCOM" },
  { institute_value: "guru nanak college", ug_degree: "MCA" },
  { institute_value: "jesus and mary college", ug_degree: null },
  {
    institute_value: "symbiosis college of arts and commerce pune",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "sri venkateswara university",
    ug_degree: "Masters Degree",
  },
  { institute_value: "symbiosis international", ug_degree: "Master of Laws" },
  { institute_value: "ethiraj college for women", ug_degree: null },
  {
    institute_value: "delhi college of arts and commerce",
    ug_degree: "Bachelors Degree",
  },
  {
    institute_value: "ramnarain ruia autonomous college mumbai",
    ug_degree: "MSC",
  },
  { institute_value: "cmj university ri-bhoi", ug_degree: null },
  {
    institute_value: "yashwantrao chavan maharashtra open university",
    ug_degree: null,
  },
  { institute_value: "chandigarh university", ug_degree: "MA" },
  { institute_value: "nc college of engineering panipat", ug_degree: "BE" },
  {
    institute_value: "national law school of india university",
    ug_degree: "Masters Degree",
  },
  {
    institute_value: "chaudhary charan singh haryana agricultural university",
    ug_degree: "MCA",
  },
  {
    institute_value: "manonmaniam sundaranar university tirunelveli",
    ug_degree: null,
  },
  { institute_value: "mahatma gandhi university kottayam", ug_degree: "BCOM" },
  { institute_value: "hindu college", ug_degree: "BSC" },
  { institute_value: "mount carmel college", ug_degree: "BSC" },
  { institute_value: "tamil nadu agricultural university", ug_degree: "BSC" },
  { institute_value: "newman college thodupuzha", ug_degree: null },
  { institute_value: "government college of technology", ug_degree: "BE" },
  { institute_value: "sacred heart college", ug_degree: "BSC" },
];
